/**
 * @name: 礼品卡管理-礼品卡查询接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡查询接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ICardList, ICardListParam, ICardSale } from "./types";

/**
 * 分页查询
 * @param params 查询参数
 * @returns
 */
export const cardListQueryApi = (params: ICardListParam) => get<IPageRes<ICardList[]>>("/card/gift/coupon/dtl/query", params)

/**
 * 导出
 * @param params 查询参数
 * @returns
 */
export const cardListExportApi = (params: ICardListParam) => get("/card/gift/coupon/dtl/export", params, "blob")

/**
 * 查询消费明细
 * @param cardNumber 卡号
 * @returns
 */
export const cardListDetailApi = (cardNumber: string) => get<ICardSale[]>("/card/gift/coupon/consumptionDetails", {cardNumber})
