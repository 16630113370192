
/**
 * @name: 礼品卡管理-礼品卡生成记录
 * @author:
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡生成记录
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import { cardRecordCreateApi, cardRecordQueryApi, cardRecordExportApi, cardRecordExportCardApi, cardRecordExportCardUseApi, cardConsumptionDetailsApi, cardCouponDetailApi } from "@/apis/card/record"
import { ICardDetail, ICardRecord, ICardRecordParam } from "@/apis/card/record/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";
import { ICardSale } from "@/apis/card/list/types";
import { cardListDetailApi } from "@/apis/card/list";

const pickerOptions = {
  disabledDate: (time: any) => {
    return time.getTime() < Date.now()
  }
}

@Component({})
export default class cardRecord extends Vue {
  pickerOptions = pickerOptions
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICardRecord[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICardRecordParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    menuWidth: 360,
    addTitle: "生成礼品卡",
    dialogWidth: '1200px',
    viewTitle: "生成批次详情",
    column: [
      {
        "label": "生成批次ID",
        "prop": "giftCouponId",
        "align": "left",
        "width": 180,
        "search": true,
        "slot": true,
        "addHide": true
      },
      {
        "label": "礼品卡类别",
        "prop": "giftCategoryName",
        "align": "center",
        "overHidden": true,
        "viewHide": true,
        "search": true,
        "addHide": true
      },
      {
        "label": "礼品卡类别",
        "prop": "giftCategoryId",
        "hide": true,
        "viewHide": true,
        "type": "select",
        "dicUrl": "/api/card/gift/category/getList?giftCategoryStatus=1",
        "dicFormatter": (res: any) => ({list: res.data, label: 'giftCategoryName', value: 'giftCategoryId'}),
        "rules": [
          {required: true, message: "礼品卡类别不能为空", trigger: "blur"}
        ]
      },
      {
        "label": "面值(元)",
        "prop": "denomination",
        "align": "center",
        "viewHide": true,
        "type": "number",
        "rules": [
          {required: true, message: "面值不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('面值不能为空'))
              }
              if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                return callback(new Error('请输入有效的面值'))
              }
              if (parseFloat(value) > 999999.99) {
                return callback(new Error('面值最大999999.99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        "label": "数量",
        "prop": "gnerateQty",
        "align": "center",
        "viewHide": true,
        "type": "number",
        "rules": [
          {required: true, message: "数量不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('数量不能为空'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('数量只能正整数'))
              }
              if (parseInt(value) > 300000) {
                return callback(new Error('数量最大300000'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        "label": "有效期至",
        "prop": "expirationTime",
        "align": "center",
        "type": "daterange",
        "search": true,
        "width": 150,
        "viewHide": true,
        "addSlot": true,
        "rules": [
          {required: true, message: "请选择有效期时间", trigger: "blur"}
        ]
      },
      {
        "label": "生成时间",
        "prop": "addTime",
        "align": "center",
        "type": "daterange",
        "search": true,
        "width": 150,
        "viewHide": true,
        "addHide": true
      },
      {
        "label": "经办人",
        "prop": "addName",
        "align": "center",
        "width": 150,
        "viewHide": true,
        "addHide": true
      },
      {
        "label": "备注",
        "prop": "remarks",
        "type": "textarea",
        "align": "center",
        "overHidden": true,
        "viewHide": true,
        "maxlength": 100,
        "showWordLimit": true
      }
    ]
  }

  // 详情
  viewLoading = false
  viewData: any = []
  viewForm: any = {}
  viewQueryParam: any = {
    page: 1,
    limit: 10
  }
  viewTableTotal = 0

  viewCrudOption: ICrudOption = {
    viewTitle: '卡号消费明细',
    appendToBody: true,
    column: [
      {
        type: "index",
        label: "序号",
        width: 50,
        align: "center",
        viewHide: true
      },
      {
        label: "卡号",
        align: "center",
        search: true,
        prop: "cardNumber",
        slot: true,
        viewSlot: true
      },
      {
        label: "余额",
        prop: "balancePrice",
        align: "center",
        viewHide: true
      },
      {
        label: "绑定账号",
        prop: "userPhone",
        align: "center",
        viewHide: true
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        viewHide: true,
        type: "select",
        dicData: [
          {
            label: "未激活",
            value: 1
          },
          {
            label: "已激活",
            value: 2
          },
          {
            label: "已使用",
            value: 3
          },
          {
            label: "已用完",
            value: 4
          },
          {
            label: "已过期",
            value: 5
          }
        ]
      },
      {
        label: "激活时间",
        prop: "activateTime",
        align: "center",
        viewHide: true
      },
      {
        label: "使用完成时间",
        prop: "useFinishTime",
        align: "center",
        viewHide: true
      },
    ]
  }

  cardSaleList: ICardSale[] = []

  viewSaleDetail (row: ICardDetail, index: number) {
    cardListDetailApi(row.cardNumber).then(e => {
      this.cardSaleList = e;
      // @ts-ignore
      this.$refs.viewCrudRef.rowView(row, index)
    })
  }

  getViewList () {
    this.viewLoading = true
    cardCouponDetailApi(this.viewQueryParam).then(e => {
      this.viewData = e.list
      this.viewTableTotal = e.total
      this.viewLoading = false
    })
  }

  openView (row: ICardRecord, index: number) {
    this.viewQueryParam.page = 1
    this.viewQueryParam.limit = 10
    this.viewQueryParam.giftCouponId = row.giftCouponId
    this.viewQueryParam.cardNumber = ''

    this.getViewList()
    // @ts-ignore
    this.$refs.crudRef.rowView(row, index)
  }

  getList () {
    this.tableLoading = true
    const query: ICardRecordParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.expirationTime && query.expirationTime.length) {
      query.expirationStartTime = query.expirationTime[0]
      query.expirationEndTime = query.expirationTime[1]
    } else {
      query.expirationStartTime = ''
      query.expirationEndTime = ''
    }
    delete query.expirationTime

    cardRecordQueryApi(query).then(e => {
      if (e) {
        this.tableData = (e.list || []).map(item => {
          return {
            ...item,
            export1Status: false,
            export2Status: false,
            export3Status: false,
          }
        })
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  openAdd () {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.modelForm.expirationTime = ''
  }

  rowSave (form: ICardRecord, done: Function, loading: Function) {
    form.expirationTime = form.expirationTime + ' 23:59:59'
    cardRecordCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  exportExcel () {
    const query: ICardRecordParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.expirationTime && query.expirationTime.length) {
      query.expirationStartTime = query.expirationTime[0]
      query.expirationEndTime = query.expirationTime[1]
    } else {
      query.expirationStartTime = ''
      query.expirationEndTime = ''
    }
    delete query.expirationTime
    cardRecordExportApi(query).then(e => {
      exportFile(e, "礼品卡生成记录.xlsx")
    })
  }

  export1Excel (row: ICardRecord) {
    row.export1Status = true
    cardRecordExportCardApi(row.giftCouponId).then(e => {
      exportFile(e, "卡号信息.xlsx")
      row.export1Status = false
    })
  }

  export2Excel (row: ICardRecord) {
    row.export2Status = true
    cardRecordExportCardUseApi(row.giftCouponId).then(e => {
      exportFile(e, "卡号使用情况.xlsx")
      row.export2Status = false
    })
  }

  export3Excel (row: ICardRecord) {
    row.export3Status = true
    cardConsumptionDetailsApi(row.giftCouponId).then(e => {
      exportFile(e, "卡号消费明细.xlsx")
      row.export3Status = false
    })
  }

  created () {
    this.getList()
  }
}
