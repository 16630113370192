/**
 * @name: 礼品卡管理-礼品卡生成记录接口文件
 * @author:
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡生成记录接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ICardDetail, ICardDetailParam, ICardRecord, ICardRecordParam } from "./types";

/**
 * 分页查询
 * @param param 查询参数
 * @returns
 */
export const cardRecordQueryApi = (param: ICardRecordParam) => get<IPageRes<ICardRecord[]>>("/card/gift/coupon/query", param)
/**
 * 创建
 * @param data
 * @returns
 */
export const cardRecordCreateApi = (data: ICardRecord) => postJ("/card/gift/coupon/create", data)

/**
 * 导出
 * @param param
 * @returns
 */
export const cardRecordExportApi = (param: ICardRecordParam) => get("/card/gift/coupon/export", param, "blob")

/**
 * 导出卡号信息
 * @param giftCouponId
 * @returns
 */
export const cardRecordExportCardApi = (giftCouponId: string) => get("/card/gift/coupon/export/card", {id: giftCouponId}, "blob")
/**
 * 导出卡号使用情况
 * @param giftCouponId
 * @returns
 */
export const cardRecordExportCardUseApi = (giftCouponId: string) => get("/card/gift/coupon/export/card/use", {id: giftCouponId}, "blob")
/**
 * 导出卡号消费明细
 * @param giftCouponId
 * @returns
 */
export const cardConsumptionDetailsApi = (giftCouponId: string) => get("/card/gift/coupon/export/card/consumptionDetails", {id: giftCouponId}, "blob")

/**
 * 查询详情
 * @param param 查询参数
 * @returns
 */
export const cardCouponDetailApi = (param: ICardDetailParam) => postJ<IPageRes<ICardDetail[]>>(`/card/gift/coupon/detail?page=${param.page}&limit=${param.limit}`, param)
